@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior: none;
  position: relative;
  height: 100%;
}

body {
  background: black;
  font-size: 14px;
  color: white;
  margin: 0;
  padding: 0;
}

/* neutral-500 */
.s-touch {
  color: rgb(115 115 115);
}
.s-active {
  color: rgb(115 115 115);
}
.swiper-slide-next {
  color: rgb(115 115 115);
}
