.range-slider {
    background: rgb(38 38 38);
}
.range-slider .range-slider__range {
    background: rgb(64 64 64);
}
.range-slider .range-slider__thumb {
    background: rgb(64 64 64);
}

.single-thumb .range-slider__thumb[data-lower] {
    width: 0;
}

.single-thumb .range-slider__range {
    border-radius: 6px;
}

.slide-progress {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: rgb(38 38 38);
}
.slide-progress .slide-progress__range {
    border-radius: 6px;
    background: rgb(64 64 64);
    height: 100%;
}